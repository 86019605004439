<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-row type="flex" justify="end">
        <el-col :span="8" class="lightgreen-box">
          <el-input
            v-model="searchphone"
            autocomplete="off"
            placeholder="可输入名字或手机进行模糊搜索"
          ></el-input>
        </el-col>
        <el-col :span="2" class="orange-box ml20">
          <el-button type="primary" @click="loadItems()">点击搜索</el-button>
        </el-col>
      </el-row>
    </div>
    <el-table
      :data="items"
      style="width: 100%; margin-bottom: 20px"
      row-key="_id"
      border
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column prop="userId" label="会员ID"></el-table-column>
      <el-table-column prop="meetperson" label="就诊人"></el-table-column>
      <el-table-column prop="doctor" label="预约医生"></el-table-column>
      <el-table-column prop="mobile" label="预约手机"></el-table-column>
      <el-table-column label="预约时间">
        <template slot-scope="scope">
          {{ scope.row.start }}-{{ scope.row.products.firsttime }}-{{
            scope.row.products.endtime
          }}
        </template>
      </el-table-column>
      <el-table-column label="订单状态">
        <template slot-scope="scope">
          {{ statustext(scope.row.status) }}
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button size="mini" v-if="!scope.row.signin && scope.row.status === 'paid'" @click="signin(scope.row)">签到</el-button>
          <el-button size="mini" v-if="scope.row.signin" disabled>已签到</el-button>
          <el-button size="mini" @click="goToUserDetails(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      background
      @current-change="handlePageChange"
      @size-change="handlePageSizeChange"
      :current-page="currentPage"
      :page-sizes="[5, 10, 20, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-dialog title="签到" :visible.sync="visible">
      <el-form :inline="true" :model="signininfo">
        <el-form-item label="就诊人" :label-width="formLabelWidth">
          <el-input v-model="signininfo.meetperson" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="预约医生" :label-width="formLabelWidth">
          <el-input v-model="signininfo.doctor" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="预约手机" :label-width="formLabelWidth">
          <el-input v-model="signininfo.mobile" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="预约时间" :label-width="formLabelWidth">
          <el-input v-model="signininfo.start" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="订单编号" :label-width="formLabelWidth">
          <el-input v-model="signininfo.out_trade_no" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="预约号码" :label-width="formLabelWidth">
          <el-input v-model="signininfo.enteredCode" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="特殊标记" :label-width="formLabelWidth">
          <el-radio v-model="signininfo.markings" label=false>否</el-radio>
          <el-radio v-model="signininfo.markings" label=true>是</el-radio>
        </el-form-item>
        <div>{{ msgwring }}</div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" @click="confirmsignin()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="订单详情" :visible.sync="detailvisible">
      <el-descriptions direction="vertical" :column="4" border>
        <el-descriptions-item label="会员ID">{{ detail.userId }}</el-descriptions-item>
        <el-descriptions-item label="就诊人">{{ detail.meetperson }}</el-descriptions-item>
        <el-descriptions-item label="就诊医生">{{ detail.doctor }}</el-descriptions-item>
        <el-descriptions-item label="预约手机">{{ detail.mobile }}</el-descriptions-item>
        <el-descriptions-item label="预约门诊">{{ detail.clinicname }}</el-descriptions-item>
        <el-descriptions-item label="下单时间">{{ changetime(detail.createdAt) }}</el-descriptions-item>
        <el-descriptions-item label="订单状态">{{ statustext(detail.status) }}</el-descriptions-item>
        <el-descriptions-item label="是否签到">{{ signistext(detail.signin) }}</el-descriptions-item>
      </el-descriptions>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detailvisible = false">关 闭</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import { VueEditor } from "vue2-editor";

export default {
  components: { VueEditor },
  data() {
    return {
      visible:false,
      detailvisible:false,
      enteredCode:"",
      msgwring:"",
      formLabelWidth: "120px",
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页数据量
      total: 0, // 总数据量
      items: [],
      searchphone:"",
      signintext:{
        false:"未签到",
        true:"已签到"
      },
      statusmap:{
        created:"待支付",
        paid:"已付款",
        cancelled:"已取消",
        finish:"已完成",
        expire:"已过期",
        refund:"已退款",
        refunding:"退款中",
        lapse:"已失效"
      },
      signininfo:{},
      detail:{}
    };
  },
  methods: {
    statustext(text) {
      return this.statusmap[text] || "未知状态"
    },
    signistext(text) {
      return this.signintext[text] || "未知状态"
    },
    changetime(text) {
      const date = new Date(text);
      const options = { timeZone: 'Asia/Shanghai' };
      const formattedDate = date.toLocaleString('zh-CN', options);
      return formattedDate
    },
    handlePageChange(page) {
      this.currentPage = page;
      this.loadItems();
    },
    handlePageSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.loadItems();
    },
    loadItems() {
      const data = {
        currentPage: this.currentPage,
        pageSize: this.pageSize,
        searchphone: this.searchphone,
      };
      this.$api.meetorder.getpagination(data).then((res) => {
        this.currentPage = res.data.pagination.currentPage;
        this.pageSize = res.data.pagination.pageSize;
        this.total = res.data.pagination.total;
        this.items = res.data.data;
      });
    },
    formatterTime(row, column) {
      let data = row[column.property];
      return /\d{4}-\d{1,2}-\d{1,2}/g.exec(data);
    },
    signin(data){
      this.visible = true
      this.signininfo = data
      this.enteredCode = ""
      this.msgwring = ""
    },
    confirmsignin(){
      const data = {
        _id: this.signininfo._id,
        enteredCode: this.signininfo.enteredCode,
        markings:this.signininfo.markings,
      };
      this.$api.meetorder.confirmsignin(data).then((res) => {
        if(res.data.code === 200){
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.visible = false
          this.loadItems();
        }else{
          this.msgwring = res.data.msg
        }
      });
    },
    goToUserDetails(data) {
      this.detailvisible = true
      this.detail = data
    },
  },
  created() {
    this.loadItems();
  },
};
</script>
<style>
button:focus {
      outline: none;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.el-row {
  margin-bottom: 20px;
}
.el-row :last-child {
  margin-bottom: 0;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #99a9bf;
}
.bg-purple {
  background: #d3dce6;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.logo {
  width: 100px;
  height: 100px;
  border-radius: 10px;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.ml20 {
  margin-left: 20px;
}
.box-card {
  width: 100%;
}
</style>
